import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Circuit } from '../../domain/models';
import localizeName from '../../utils';
import './circuitsList.css';

type ExpandableListProps = {
    circuits: Circuit[],
    onCircuitChange: (circuit?: Circuit) => void,
    selectedCircuit?: Circuit
};

const CircuitsList: FC<ExpandableListProps> = ({ circuits, onCircuitChange, selectedCircuit }) => {
    const { t } = useTranslation();
    if (!circuits) {
        return null;
    }

    return (
        <List component="nav">
            {circuits.map((circuit: Circuit) => (
                <ListItem
                    key={`circuit-${circuit.id}`}
                    onClick={() => {
                        onCircuitChange(circuit);
                    }}
                    selected={selectedCircuit && selectedCircuit.id === circuit.id}
                    button
                >
                    {/* TODO: localize */}
                    <ListItemText
                        className='circuitPrimaryText'
                        primary={localizeName(circuit.name).substring(0, 25)}
                    />
                    <ListItemText
                        className='circuitSecondaryText'
                        secondary={`${circuit.places.length} ${t('leftPanel.steps')}`}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default CircuitsList;
