import { CircularProgress } from '@material-ui/core';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { getCircuits, getPlaces } from '../../api';
import { Circuit, CityType, PlaceType, Filters } from '../../domain/models';
import JsonRetriever from '../../retrievers/jsonRetriever';

// TODO: city referential + dynamic selection
const Paris: CityType = {
    name: {
        fr: 'Paris',
        en: 'Paris',
    },
    id: 'paris',
    initialZoom: 12,
    coordinates: {
        lat: 48.86282406,
        lon: 2.3428424,
    },
    icon: 'paris.svg',
};

const London: CityType = {
    name: {
        fr: 'Londres',
        en: 'London',
    },
    id: 'london',
    initialZoom: 11.5,
    coordinates: {
        lat: 51.499819,
        lon: -0.126059,
    },
    icon: 'london.svg',
};

const NewYork: CityType = {
    name: {
        fr: 'New York',
        en: 'New York',
    },
    id: 'new-york',
    initialZoom: 12,
    coordinates: {
        lat: 40.720307,
        lon: -74.003267,
    },
    icon: 'new-york.svg',
};

type ContextType = {
    city: CityType,
    places: PlaceType[],
    circuits: Circuit[],
    baseUrl: string,
    cities: CityType[],
    setCity: Dispatch<SetStateAction<CityType>>,
    parametersFilters: Filters,
    setParametersFilters: Dispatch<SetStateAction<Filters>>,
    selectedCategory: string | undefined,
    setSelectedCategory: Dispatch<SetStateAction<string | undefined>>,
    listCategory: Array<string>,
    setListCategory: Dispatch<SetStateAction<string[]>>,
    selectedSubCategory: string |undefined
    setSelectedSubCategory: Dispatch<SetStateAction<string | undefined>>,
    listSubCategory: Array<string>,
    setListSubCategory: Dispatch<SetStateAction<string[]>>,

};

const Context = createContext({} as ContextType);

type ContextProviderProps = {
    children: ReactNode,
    baseUrl: string,
};

const usePlaces = () => {
    const { places } = useContext(Context);

    return places;
};

const useCircuits = () => {
    const { circuits } = useContext(Context);

    return circuits;
};

const useCity = () => {
    const { city, setCity } = useContext(Context);

    return { city, setCity };
};

const useBaseUrl = () => {
    const { baseUrl } = useContext(Context);

    return baseUrl;
};

const useCities = () => {
    const { cities } = useContext(Context);

    return cities;
};

const useParametersFilters = () => {
    const { parametersFilters, setParametersFilters } = useContext(Context);

    return { parametersFilters, setParametersFilters };
};

const useSelectedCategory = () => {
    const { selectedCategory, setSelectedCategory } = useContext(Context);

    return { selectedCategory, setSelectedCategory };
};

const useListCategory = () => {
    const { listCategory, setListCategory } = useContext(Context);

    return { listCategory, setListCategory };
};
const useSelectedSubCategory = () => {
    const { selectedSubCategory, setSelectedSubCategory } = useContext(Context);

    return { selectedSubCategory, setSelectedSubCategory };
};
const useListSubCategory = () => {
    const { listSubCategory, setListSubCategory } = useContext(Context);

    return { listSubCategory, setListSubCategory };
};

const ContextProvider = ({ children, baseUrl }: ContextProviderProps) => {
    const [places, setPlaces] = useState<PlaceType[]>([]);
    const [circuits, setCircuits] = useState<Circuit[]>([]);
    const [city, setCity] = useState<CityType>(NewYork);
    const [cities] = useState<CityType[]>([Paris, London, NewYork]);
    const retriever = new JsonRetriever(`${baseUrl}/json`);
    const [inError, setInError] = useState(false);
    const [parametersFilters, setParametersFilters] = useState<Filters>({
        free: false,
        accessible: false,
        kidsFriendly: false,
        rating: 0,
    });
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [listCategory, setListCategory] = useState<Array<string>>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>();
    const [listSubCategory, setListSubCategory] = useState<Array<string>>([]);

    useEffect(() => {
        if (!city) {
            return;
        }

        const fetchData = async () => {
            try {
                const allPlaces = await getPlaces(retriever, city.id);
                setPlaces(allPlaces);

                try {
                    const allCircuits = await getCircuits(retriever, city.id);

                    setCircuits(allCircuits.map((circuitType) => new Circuit({
                        ...circuitType,
                        places: circuitType.places.map(
                            (id) => allPlaces.find((place) => place.id === id),
                        ).filter(Boolean) as PlaceType[],
                    })));
                } catch (e) {
                    setInError(true);
                    setCircuits([]);
                    console.error(e);
                }
            } catch (e) {
                setInError(true);
                setPlaces([]);
                console.error(e);
            }
        };

        fetchData();
    }, [city]);

    return (
        <Context.Provider value={{
            city,
            places,
            circuits,
            baseUrl,
            cities,
            setCity,
            parametersFilters,
            setParametersFilters,
            selectedCategory,
            setSelectedCategory,
            listCategory,
            setListCategory,
            selectedSubCategory,
            setSelectedSubCategory,
            listSubCategory,
            setListSubCategory,
        }}
        >
            {
                (places.length > 0 && circuits.length > 0) || inError
                    ? children
                    : (
                        <div style={{
                            display: 'flex',
                            placeContent: 'center',
                            marginTop: '100%',
                        }}
                        >
                            <CircularProgress />
                        </div>
                    )
            }
        </Context.Provider>
    );
};

export {
    ContextProvider,
    useCircuits,
    useCity,
    usePlaces,
    useBaseUrl,
    useCities,
    useParametersFilters,
    useSelectedCategory,
    useListCategory,
    useSelectedSubCategory,
    useListSubCategory,
};
