import { Card } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { categories } from '../../constants';
import { SiteIcon } from '../icons';
import './sitesCategories.css';

type SitesCategoriesProps = { onChange: (cat: string) => void, listCategory?: Array<string> };

const SitesCategories: FC<SitesCategoriesProps> = ({
    onChange,
    listCategory,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            className="sitesCategories"
        >
            {categories.map((cat) => (
                <div
                    key={`cat-${cat}`}
                    className="category"
                >
                    <SiteIcon
                        category={cat.toLowerCase()}
                        highlighted={listCategory?.includes(cat.toLowerCase())}
                        onSiteClick={() => {
                            onChange(cat);
                        }}
                    />
                    <div className="categoryText">{t(`category.${cat.toLowerCase()}`)}</div>
                </div>
            ))}
        </Card>
    );
};

export default SitesCategories;
