import { CircuitType, PlaceType } from '../domain/models';
import { DataRetriever } from './dataRetriever';

class JsonRetriever implements DataRetriever {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async getPlaces(city: string): Promise<PlaceType[]> {
        const places = await (await fetch(`${this.baseUrl}/places/${city.toLowerCase()}.json`)).json();

        return places;
    }

    async getCircuits(city: string): Promise<CircuitType[]> {
        const circuits = await (await fetch(`${this.baseUrl}/circuits/${city.toLowerCase()}.json`)).json();

        return circuits;
    }
}

export default JsonRetriever;
