import { Tab, Tabs } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC } from 'react';
import './leftPanel.css';
import { useTranslation } from 'react-i18next';
import { Circuit, Filters, PlaceType } from '../../domain/models';
import CircuitDetail from '../circuitDetails';
import CircuitsList from '../circuitsList';
import FilterParameters from '../filters-parameters';
import PlaceDetail from '../placeDetail';
import PlacesList from '../placesList';

import SearchBar from '../searchBar';

type LeftPanelProps = {
    // Circuits Props
    circuits: Circuit[],
    onCircuitChange: (circuit?: Circuit) => void,
    selectedCircuit?: Circuit,
    // Places Props
    places: PlaceType[],
    onPlaceSelect: (place: PlaceType) => void,
    selectedPlace?: PlaceType,
    openPlaceDetail: boolean,
    onClosePlaceDetail: () => void,
    tabIndex?: number,
    onTabIndexChange: (index?: number) => void,
    onFiltersChange: (filters: Filters) => void,
    // Category and Subcategory props
    onCategoryChange: (cat: string) => void,
    onSubCategoryChange: (subCat: string) => void,
    selectedSubCategory?: string
    listCategory?: Array<string>
    listSubCategory?: Array<string>
    openCircuitDetails: boolean
    setOpenCircuitDetails: (open: boolean) => void
};

const LeftPanel: FC<LeftPanelProps> = ({
    circuits,
    onCircuitChange,
    selectedCircuit,
    places,
    onPlaceSelect,
    selectedPlace,
    openPlaceDetail,
    onClosePlaceDetail,
    tabIndex = 0,
    onTabIndexChange,
    onCategoryChange,
    onSubCategoryChange,
    onFiltersChange,
    selectedSubCategory,
    listCategory,
    listSubCategory,
    openCircuitDetails,
    setOpenCircuitDetails,
}) => {
    const { t } = useTranslation();

    return (
        <div className="leftPanelContainer">
            <div className="searchBarContainer">
                <SearchIcon />
                <SearchBar
                    onSelection={(optionSelected) => {
                        if (optionSelected instanceof Circuit) {
                            onCircuitChange(optionSelected);
                            onTabIndexChange(0);
                        } else {
                            onPlaceSelect(optionSelected);
                            onTabIndexChange(1);
                        }
                    }}
                    placesAndCircuits={[...places, ...circuits]}
                />
            </div>

            <Tabs onChange={(event, newValue) => onTabIndexChange(newValue)} value={tabIndex} variant="fullWidth">
                <Tab label={t('leftPanel.our_tours')} />
                <Tab label={t('leftPanel.poi')} />
            </Tabs>

            {tabIndex === 0 && (
                <div className="leftPanelContent">
                    {!openCircuitDetails && (
                        <CircuitsList
                            circuits={circuits}
                            onCircuitChange={(circuit) => {
                                onCircuitChange(circuit);
                                setOpenCircuitDetails(true);
                            }}
                            selectedCircuit={selectedCircuit}
                        />
                    )}
                    {openCircuitDetails && selectedCircuit && (
                        <CircuitDetail
                            circuit={selectedCircuit}
                            onClose={() => {
                                onTabIndexChange();
                            }}
                            onGoToSteps={() => onTabIndexChange(1)}
                            onPrevious={() => {
                                setOpenCircuitDetails(false);
                                onCircuitChange();
                            }}
                        />
                    )}
                </div>

            )}

            {tabIndex === 1 && (
                <div className="leftPanelContent">
                    <FilterParameters onFiltersChange={onFiltersChange} />
                    {!openPlaceDetail && (
                        <PlacesList
                            listCategory={listCategory}
                            listSubCategory={listSubCategory}
                            onCategoryChange={onCategoryChange}
                            onCloseCircuit={() => {
                                onTabIndexChange(0);
                                onCircuitChange();
                                setOpenCircuitDetails(false);
                            }}
                            onPlaceChange={onPlaceSelect}
                            onSubCategoryChange={onSubCategoryChange}
                            places={places}
                            selectedCircuit={selectedCircuit}
                            selectedSubCategory={selectedSubCategory}
                        />
                    )}
                    {openPlaceDetail && selectedPlace && (
                        <PlaceDetail
                            onPrevious={onClosePlaceDetail}
                            place={selectedPlace}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default LeftPanel;
