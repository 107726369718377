import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './subCategoryList.css';

type SubCategoriesListProps = {
    handleListItemClick: (subCat: string) => void,
    // subCategories: string[],
    selectedSubCategory?: string,
    listSubCategory?: Array<string>|undefined,
};

const SubCategoriesList: FC<SubCategoriesListProps> = ({
    handleListItemClick,
    selectedSubCategory,
    listSubCategory,
}) => {
    const { t } = useTranslation();

    return (
        <div className="listContainer">
            {listSubCategory?.map((subCat) => (
                <span
                    key={`subcat-${subCat}`}
                    className={selectedSubCategory === subCat ? 'subCategory selected' : 'subCategory'}
                    onClick={() => handleListItemClick(subCat)}
                    onKeyPress={() => handleListItemClick(subCat)}
                    role="button"
                    tabIndex={0}
                >
                    {t(`subCategory.${subCat}`)}

                </span>
            ))}
        </div>
    );
};

export default SubCategoriesList;
