import {
    BottomNavigation,
    BottomNavigationAction,
    Divider,
    IconButton,
    List,
    ListItem,
    ListSubheader,
    Typography,
    Button,
} from '@material-ui/core';
import { Restore, Place, Search, Close, ArrowDropDown } from '@material-ui/icons';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Circuit, Filters, PlaceType } from '../../domain/models';
import localizeName from '../../utils';
import CircuitDetail from '../circuitDetails';
import FilterParameters from '../filters-parameters';
import PlaceDetail from '../placeDetail';
import SearchBar from '../searchBar';
import SitesCategories from '../sitesCategories';
import SubCategoriesList from '../subCategoriesList';
import './navMobile.css';

interface AdditionnalProps {
    circuits: Circuit[];
    places: PlaceType[];
    onCircuitSelect: (circuit?: Circuit) => void;
    onPlaceSelect: (place: PlaceType) => void;
    onClosePlaceDetail: () => void;
    selectedPlace?: PlaceType;
    tabIndex?: number;
    onTabIndexChange: (index?: number) => void;
    onFilterChange: (filters: Filters) => void;
    selectedCircuit?: Circuit;
    onCategoryChange: (category: string) => void,
    onSubCategoryChange: (subcategory: string) => void,
    listCategory?: Array<string>,
    listSubCategory?: Array<string>,
    selectedSubCategory?: string,
    openCircuitDetails: boolean
    setOpenCircuitDetails: (open: boolean) => void,
}

const NavMobile: FC<AdditionnalProps> = ({
    circuits,
    places,
    onCircuitSelect,
    onPlaceSelect,
    onClosePlaceDetail,
    selectedPlace,
    tabIndex,
    onTabIndexChange,
    onFilterChange,
    selectedCircuit,
    onCategoryChange,
    onSubCategoryChange,
    selectedSubCategory,
    listCategory,
    listSubCategory,
    openCircuitDetails,
    setOpenCircuitDetails,
}) => {
    const { t } = useTranslation();
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const handleScroll = () => {
        if (containerRef.current) {
            setIsButtonVisible(containerRef.current.scrollTop > 180);
        }
    };

    const handleClick = () => {
        containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleCircuitSelect = (circuit: Circuit) => {
        onTabIndexChange(1);
        onCircuitSelect(circuit);
        setOpenCircuitDetails(true);
    };

    const handlePlaceSelect = (place: PlaceType) => {
        onTabIndexChange(2);
        onPlaceSelect(place);
    };

    return (
        <div>
            {tabIndex === 0 && (
                <div className='searchBarMobile' id="openedNav">
                    <SearchBar
                        onSelection={(optionSelected) => {
                            if (optionSelected instanceof Circuit) {
                                handleCircuitSelect(optionSelected);
                            } else {
                                handlePlaceSelect(optionSelected);
                            }
                        }}
                        placesAndCircuits={[...places, ...circuits]}
                    />
                </div>

            )}
            {tabIndex === 1 && (
                <div>
                    {!openCircuitDetails && (
                        <List className='list' id='openedNav' disablePadding>
                            <ListSubheader>
                                <div className='listSubHeader'>
                                    <IconButton
                                        onClick={() => onTabIndexChange()}
                                        onKeyPress={() => onTabIndexChange()}
                                    >
                                        <ArrowDropDown />
                                    </IconButton>
                                </div>
                                <Divider />
                            </ListSubheader>
                            { circuits.map((circuit) => (
                                <ListItem
                                    className='elementListItems'
                                    onClick={() => handleCircuitSelect(circuit)}
                                    onKeyPress={() => handleCircuitSelect(circuit)}
                                    selected={selectedCircuit?.id === circuit.id}
                                    button
                                >
                                    { localizeName(circuit.name) }
                                </ListItem>
                            )) }
                        </List>
                    )}
                    {openCircuitDetails && selectedCircuit && (
                        <div className='detail' id="openedNav">
                            <CircuitDetail
                                circuit={selectedCircuit}
                                onClose={() => {
                                    onCircuitSelect();
                                    setOpenCircuitDetails(false);
                                }}
                                onGoToSteps={() => onTabIndexChange(2)}
                                onPrevious={() => {
                                    onCircuitSelect();
                                    setOpenCircuitDetails(false);
                                }}
                            />
                        </div>

                    )}
                </div>

            )}
            {tabIndex === 2 && (
                <div>
                    {!selectedPlace && (
                        <div
                            ref={containerRef}
                            className='list'
                            id="openedNav"
                            onScroll={handleScroll}
                        >
                            <List disablePadding>
                                <div
                                    className={isButtonVisible ? 'scrollTopButton' : 'scrollTopButton disabled'}
                                >
                                    <Button className="upIcon" onClick={handleClick}>
                                        <KeyboardDoubleArrowUpRoundedIcon />
                                    </Button>
                                    <Divider absolute />
                                </div>
                                <ListSubheader disableSticky>
                                    <div className='listSubHeader'>
                                        <FilterParameters onFiltersChange={onFilterChange} />
                                        <IconButton
                                            onClick={() => onTabIndexChange()}
                                            onKeyPress={() => onTabIndexChange()}
                                        >
                                            <ArrowDropDown />
                                        </IconButton>
                                    </div>
                                </ListSubheader>
                                {!selectedCircuit && (
                                    <div>
                                        <SitesCategories
                                            listCategory={listCategory}
                                            onChange={onCategoryChange}
                                        />
                                        {listCategory?.length === 1 && (
                                            <SubCategoriesList
                                                handleListItemClick={onSubCategoryChange}
                                                listSubCategory={listSubCategory}
                                                selectedSubCategory={selectedSubCategory}
                                            />
                                        )}
                                    </div>
                                )}
                                {selectedCircuit && (
                                    <div className='selectedCircuit' id="openedNav">
                                        <Typography
                                            color='textPrimary'
                                            variant='subtitle1'
                                        >{localizeName(selectedCircuit.name)}
                                        </Typography>
                                        <IconButton
                                            onClick={() => {
                                                onTabIndexChange(1);
                                                onCircuitSelect();
                                                setOpenCircuitDetails(false);
                                            }}
                                            onKeyPress={() => {
                                                onCircuitSelect();
                                                setOpenCircuitDetails(false);
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </div>
                                )}
                                { places.map((place, index: number) => (
                                    <ListItem
                                        className='elementListItems'
                                        onClick={() => handlePlaceSelect(place)}
                                        onKeyPress={() => handlePlaceSelect(place)}
                                        button
                                    >
                                        {selectedCircuit && (
                                            <span className='listIndex'>
                                                { `${index + 1}.` }
                                            </span>
                                        )}
                                        { localizeName(place.name) }
                                    </ListItem>
                                )) }
                            </List>
                        </div>
                    )}
                    { selectedPlace && (
                        <div className='detail' id="openedNav">
                            <PlaceDetail
                                onPrevious={onClosePlaceDetail}
                                place={selectedPlace}
                            />
                        </div>
                    )}
                </div>
            )}

            <BottomNavigation
                className='navContainer'
                onChange={(event, newValue) => {
                    if (newValue === tabIndex) {
                        onTabIndexChange();

                        return;
                    }
                    onTabIndexChange(newValue);
                }}
                value={tabIndex}
                showLabels
            >
                <BottomNavigationAction icon={<Search />} label={t('navMobile.search')} />
                <BottomNavigationAction icon={<Restore />} label={t('leftPanel.our_tours')} />
                <BottomNavigationAction icon={<Place />} label={t('leftPanel.poi')} />
            </BottomNavigation>

        </div>
    );
};

export default NavMobile;
