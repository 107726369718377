import { Card, CardActionArea, CardContent, CardActions, Typography, Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'react-map-gl';
import { PlaceType } from '../../domain/models';
import localizeName from '../../utils';
import './popupPlace.css';

const PopupPlace: FC<{
    place?: PlaceType,
    onClose: () => void,
    onDetailClick: () => void
}> = ({ place, onClose, onDetailClick }) => {
    if (!place) {
        return null;
    }
    const { t } = useTranslation();

    return (
        <Popup
            closeButton={false}
            closeOnClick={false}
            latitude={place.coordinates.lat}
            longitude={place.coordinates.lon}
            onClose={onClose}
        >
            <Card className="popupPlaceContainer">
                <CardActionArea>
                    <CardContent>
                        <Typography component="h2" variant="h5" gutterBottom>
                            {localizeName(place.name)}
                        </Typography>
                        {place.address} - {place.zipcode}
                        <div className='subCategories'>
                            {place.subCategories.map((subCat) => (
                                <Typography
                                    className="subCategory"
                                    color="textSecondary"
                                    component="p"
                                    variant="body2"
                                > {t(`subCategory.${subCat}`)}
                                </Typography>
                            ))}
                        </div>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {localizeName(place.website) && (
                        <Button color="primary" href={localizeName(place.website)} size="small" target="_blank">
                            {t('popupPlace.website')}
                        </Button>
                    )}
                    <Button color="primary" onClick={() => onDetailClick()} size="small">
                        {t('popupPlace.seeDetails')}
                    </Button>
                    <Button color="primary" onClick={() => onClose()} size="small">
                        {t('popupPlace.close')}
                    </Button>
                </CardActions>
            </Card>
        </Popup>
    );
};

export default PopupPlace;
