import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import ParkRoundedIcon from '@mui/icons-material/ParkRounded';
import LocalSeeRoundedIcon from '@mui/icons-material/LocalSeeRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import MuseumRoundedIcon from '@mui/icons-material/MuseumRounded';
import NotListedLocationRoundedIcon from '@mui/icons-material/NotListedLocationRounded';
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import TheaterComedyRoundedIcon from '@mui/icons-material/TheaterComedyRounded';
import { IconButton } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import './site.css';

const icons: { [k: string]: ReactNode } = {
    ArtIcon: <PaletteRoundedIcon />,
    EntertainmentIcon: <TheaterComedyRoundedIcon />,
    LandmarkIcon: <MapsHomeWorkRoundedIcon />,
    MuseumIcon: <MuseumRoundedIcon />,
    EssentialsIcon: <NewReleasesRoundedIcon />,
    NatureIcon: <ParkRoundedIcon />,
    NourishmentIcon: <RestaurantRoundedIcon />,
    OffbeatIcon: <AltRouteRoundedIcon />,
    POIIcon: <LocalSeeRoundedIcon />,
    ShoppingIcon: <ShoppingCartRoundedIcon />,
    NotListedIcon: <NotListedLocationRoundedIcon />,
};

const cssMapping: { [k: string]: ReactNode } = {
    art: icons.ArtIcon,
    entertainment: icons.EntertainmentIcon,
    landmark: icons.LandmarkIcon,
    museums: icons.MuseumIcon,
    essentials: icons.EssentialsIcon,
    nature: icons.NatureIcon,
    nourishment: icons.NourishmentIcon,
    offbeat: icons.OffbeatIcon,
    poi: icons.POIIcon,
    shopping: icons.ShoppingIcon,
};

type SiteIconProps = {
    category: string,
    onSiteClick?: () => void,
    contrasted?: Boolean,
    highlighted?: Boolean,
    className?: string,
    hover?: boolean,
};

export const SiteIcon: FC<SiteIconProps> = ({
    category,
    contrasted = false,
    highlighted = false,
    onSiteClick,
    className,
    hover,
}) => {
    let classIcon = `${category.replace('&', '-')}-icon`;
    const icon = cssMapping[category];
    let classHoverIcon = '';

    if (hover) {
        classHoverIcon = `${classIcon}-hover`;
    }

    if (hover) {
        classHoverIcon = `${classIcon}-hover`;
    }

    if (highlighted) {
        classIcon += '-highlighted';
    } else if (contrasted) {
        classIcon += ' contrasted';
    }

    return (
        <IconButton
            className={`${className} ${classIcon} ${classHoverIcon}`}
            onClick={() => onSiteClick && onSiteClick()}
        >
            {icon || icons.NotListedIcon}
        </IconButton>
    );
};

export default icons;
