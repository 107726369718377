import React, { ReactNode } from 'react';
import ReactMapGl, { MapRef, ViewState } from 'react-map-gl';

type MapProps = {
    viewport: ViewState,
    vwAction: (state: ViewState) => void,
    children: ReactNode,
};

const Map = React.forwardRef<MapRef, MapProps>(({ viewport, vwAction, children }, ref) => (
    <div style={{ width: '100vw', height: '100vh' }}>
        <ReactMapGl
            {...viewport}
            ref={ref}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
            onMove={(e) => vwAction(e.viewState)}
        >
            {children}
        </ReactMapGl>
    </div>
));

export default Map;
