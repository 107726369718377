import { BBox } from 'geojson';
import React, { FC } from 'react';
import { MapRef, Marker, ViewState } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import { Circuit, PlaceType } from '../../domain/models';
import { SiteIcon } from '../icons';
import './marker.css';

type MarkersProps = {
    circuitDisplayed?: Circuit,
    mapRef: MapRef | null,
    onSiteClick: (place: PlaceType) => void,
    places: PlaceType[],
    viewport: ViewState,
    vwAction: (vw: ViewState) => void,
};

const Markers: FC<MarkersProps> = ({
    circuitDisplayed,
    mapRef,
    onSiteClick,
    places,
    viewport,
    vwAction,
}) => {
    const points = places.map((place, index) => ({
        type: 'Feature',
        properties: {
            cluster: false,
            clusterId: index,
            place,
        },
        geometry: {
            type: 'Point',
            coordinates: [place.coordinates.lon, place.coordinates.lat],
        },
    }));

    if (!mapRef) {
        return null;
    }

    const mapGL = mapRef.getMap();

    const bounds = mapGL.getBounds();

    const bbox: BBox = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
    ];

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds: bbox,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20 },
    });

    if (circuitDisplayed) {
        return (
            <>
                {places.map((place) => (
                    <Marker
                        key={`marker-${place.id}`}
                        latitude={place.coordinates.lat}
                        longitude={place.coordinates.lon}
                    >
                        <SiteIcon
                            category={place.mainCategory}
                            onSiteClick={() => onSiteClick(place)}
                            contrasted
                        />
                    </Marker>
                ))}
            </>
        );
    }

    return (
        <>
            {clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                    cluster: isCluster,
                    point_count: pointCount,
                } = cluster.properties;

                if (isCluster) {
                    const onMarkerClick = () => {
                        vwAction({
                            ...viewport,
                            latitude,
                            longitude,
                            zoom: Math.min(
                                supercluster.getClusterExpansionZoom(cluster.id),
                                20,
                            ),
                        });
                        mapRef.flyTo({ center: [longitude, latitude], duration: 2000 });
                    };

                    return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            latitude={latitude}
                            longitude={longitude}
                        >
                            <div
                                className="cluster-marker"
                                onClick={onMarkerClick}
                                onKeyDown={onMarkerClick}
                                role="button"
                                style={{
                                    width: `${10 + (pointCount / points.length) * 20}px`,
                                    height: `${10 + (pointCount / points.length) * 20}px`,
                                }}
                                tabIndex={0}
                            >
                                {pointCount}
                            </div>
                        </Marker>
                    );
                }

                const { place } = cluster.properties;

                return (
                    <Marker
                        key={`marker-${place.id}`}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <SiteIcon
                            category={place.mainCategory}
                            onSiteClick={() => onSiteClick(place)}
                            contrasted
                        />
                    </Marker>
                );
            })}
        </>
    );
};

export default Markers;
