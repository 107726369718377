export const categoryMap: { [k: string]: string[] } = {
    art: [
        'art_exhibition',
        'art_contemporary',
        'art_outdoor',
    ],
    entertainment: [
        'entertainment_cinema',
        'entertainment_dance',
        'entertainment_music',
        'entertainment_show',
        'entertainment_theatre',
    ],
    landmark: [
        'landmark_administrative',
        'landmark_architecture',
        'landmark_heritage',
        'landmark_worship',
    ],
    museums: [
        'museums_art',
        'museums_history',
        'museums_sciences',
    ],
    essentials: [],
    nature: [
        'nature_park',
        'nature_watering_place',
        'nature_animals',
    ],
    nourishment: [
        'nourishment_bakery',
        'nourishment_coffee',
        'nourishment_drink',
        'nourishment_groceries',
        'nourishment_restaurant',
        'nourishment_streetfood',
    ],
    offbeat: [],
    poi: [
        'poi_location',
        'poi_history',
        'poi_pointofview',
    ],
    shopping: [
        'shopping_books',
        'shopping_clothing',
        'shopping_crafts',
    ],
};
export const categories: string[] = Object.keys(categoryMap);
