import { Grid, IconButton } from '@material-ui/core';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocation';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapRef, Marker, ViewState } from 'react-map-gl';
import CircuitRoute from './component/circuitRoute';
import {
    useBaseUrl,
    useCircuits,
    useCity,
    useListCategory,
    useListSubCategory,
    useParametersFilters,
    usePlaces,
    useSelectedCategory,
    useSelectedSubCategory,
} from './component/context';
import LeftPanel from './component/leftPanel';
import Map from './component/map';
import Markers from './component/marker';
import NavMobile from './component/navMobile';
import PopupPlace from './component/popupPlace';
import RightPanel from './component/rightPanel';
import { categoryMap } from './constants';
import { Circuit, Filters, PlaceType } from './domain/models';
import useWindowDimensions from './hooks/useWindowDimensions';
import './App.css';

const filterPlace = (
    place: PlaceType,
    activeFilters: string[],
    filters: Filters,
) => activeFilters.every((filterKey) => {
    if (filterKey === 'rating') {
        return place.rating === filters.rating;
    }

    return place[filterKey] === filters[filterKey];
});

const filterPlaces = (
    places: PlaceType[],
    listCategory?: Array<string> | undefined,
    listSubCategory?: Array<string> | undefined,
    selectedSubCategory?: string | undefined,
    selectedCircuit?: Circuit | undefined,
    parametersFilters?: Filters | undefined,
) => {
    if (selectedCircuit) {
        return selectedCircuit.places;
    }

    let filteredPlaces: PlaceType[] = places;

    if (listCategory?.length) {
        filteredPlaces = [];
        listCategory.forEach((item) => {
            const newPlaces = places.filter((place) => place.categories.includes(item));
            filteredPlaces = [...new Set(filteredPlaces.concat(newPlaces))];
        });
        if (listCategory.length === 1 && selectedSubCategory) {
            filteredPlaces = places.filter(
                (place) => place.subCategories.findIndex(
                    (subCat) => subCat === selectedSubCategory,
                ) !== -1,
            );
        }
    }

    if (parametersFilters) {
        const activeFilters = Object.keys(parametersFilters)
            .filter((key) => Boolean(parametersFilters[key]));

        if (activeFilters.length) {
            filteredPlaces = filteredPlaces.filter((place) => filterPlace(place, activeFilters, parametersFilters));
        }
    }

    return filteredPlaces;
};

type userCoords = {
    latitude: number;
    longitude: number;
}

const App = () => {
    const baseUrl = useBaseUrl();
    const places = usePlaces();
    const circuits = useCircuits();
    const {
        city,
        setCity,
    } = useCity();
    const {
        selectedCategory,
        setSelectedCategory,
    } = useSelectedCategory();
    const {
        listCategory,
        setListCategory,
    } = useListCategory();
    const {
        selectedSubCategory,
        setSelectedSubCategory,
    } = useSelectedSubCategory();
    const {
        listSubCategory,
        setListSubCategory,
    } = useListSubCategory();
    const [selectedPlace, setSelectedPlace] = useState<PlaceType>();
    const [selectedCircuit, setSelectedCircuit] = useState<Circuit>();
    const [viewport, setViewport] = useState<ViewState>();
    const [tabIndex, setTabIndex] = useState<number | undefined>();
    const [mobileTabIndex, setMobileTabIndex] = useState<number | undefined>();
    const [openPlacesDetail, setOpenPlacesDetail] = useState(false);
    const [openCircuitDetails, setOpenCircuitDetails] = useState(false);
    const [userPosition, setUserPosition] = useState<userCoords>();
    const watchPositionIdRef = useRef<number|undefined>();
    const mapRef = useRef<MapRef>(null);
    const { width } = useWindowDimensions();
    const { parametersFilters, setParametersFilters } = useParametersFilters();
    const filteredPlaces = useMemo(
        () => filterPlaces(places, listCategory, listSubCategory, selectedSubCategory, selectedCircuit, parametersFilters),
        [places, listCategory, listSubCategory, selectedSubCategory, selectedCircuit, parametersFilters],
    );
    const [locateIcon, setLocateIcon] = useState<string>('locateIcon');

    const handleSelectedCategory = (cat: string) => {
        // @ts-ignore
        if (listCategory.includes(cat)) {
            const updatedList = listCategory.filter((item) => item !== cat);
            // @ts-ignore
            setListCategory(updatedList);
        } else {
            // @ts-ignore
            setListCategory([...listCategory, cat]);
        }
    };

    const handleLocateIcon = (openedNav: string|undefined) => {
        switch (openedNav) {
            case 'openedNav':
                return 'mobileLocateIcon';
            default:
                return 'locateIcon';
        }
    };

    useEffect(() => {
        const openedNav: string|undefined = document.getElementById('openedNav')?.id;
        setLocateIcon(handleLocateIcon(openedNav));
    });

    // Place Selection
    useEffect(() => {
        if (!selectedPlace) {
            return;
        }

        // Rough adjustement for taking the menu into account on mobile
        let latitude = selectedPlace.coordinates.lat;
        let zoom = 15;
        if (width <= 600) {
            // The lat is a string in the json but a number in the model, so the parseFloat(toString) is just a quickwin to prevent any issues
            latitude = parseFloat(latitude.toString()) - 0.002;
            zoom = 15.5;
        }
        setViewport({
            latitude,
            longitude: selectedPlace.coordinates.lon,
            zoom,
            bearing: 0,
            pitch: 0,
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        });
    }, [selectedPlace]);

    // Circuit Selection
    useEffect(() => {
        if (!selectedCircuit) {
            return;
        }

        // Rough adjustement for taking the menu into account on mobile
        let latitude = selectedCircuit.places[0].coordinates.lat;
        let longitude = selectedCircuit.places[0].coordinates.lon;
        let zoom = city.initialZoom;
        if (width <= 600) {
            // The lat is a string in the json but a number in the model,
            // so the parseFloat(toString) is just a quickwin to prevent any issues
            latitude = city.coordinates.lat - 0.08;
            longitude = city.coordinates.lon;
            zoom = city.initialZoom - 1.7;
        }

        setViewport({
            latitude,
            longitude,
            zoom,
            bearing: 0,
            pitch: 0,
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        });
    }, [selectedCircuit]);

    // City Selection
    useEffect(() => {
        if (!city) {
            return;
        }

        setViewport({
            latitude: city.coordinates.lat,
            longitude: city.coordinates.lon,
            zoom: city.initialZoom,
            bearing: 0,
            pitch: 0,
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        });
    }, [city]);

    const setSubCategories = (cat: string) => {
        const subCat = categoryMap[cat];
        if (listSubCategory.includes(subCat[0])) {
            const updatedList = listSubCategory.filter((item) => !subCat.includes(item));
            setListSubCategory(updatedList);
        } else {
            setListSubCategory(listSubCategory.concat(categoryMap[cat]));
        }
    };

    const onCategoryChange = (cat: string) => {
        handleSelectedCategory(cat);
        setSelectedCategory(selectedCategory === cat ? undefined : cat);
        setSelectedSubCategory(undefined);
        setSubCategories(cat);
    };

    const resetLeftPanel = () => {
        setSelectedCategory(undefined);
        setListCategory([]);
        setSelectedSubCategory(undefined);
        setListSubCategory([]);
        setSelectedPlace(undefined);
        setSelectedCircuit(undefined);
        setMobileTabIndex(undefined);
        setOpenPlacesDetail(false);
        setOpenCircuitDetails(false);
    };

    if (!viewport) {
        return null;
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((result) => {
                    const localSetViewport = (userPos: userCoords) => {
                        setViewport({
                            ...viewport,
                            latitude: userPos.latitude,
                            longitude: userPos.longitude,
                            zoom: 17,
                            bearing: 0,
                            pitch: 0,
                            padding: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                            },
                        });
                    };
                    const setCurrentLocation = () => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                setUserPosition(position.coords);
                                localSetViewport(position.coords);
                                if (watchPositionIdRef.current) {
                                    navigator.geolocation.clearWatch(watchPositionIdRef.current);
                                }
                                const positionId = navigator.geolocation.watchPosition(
                                    (position_) => {
                                        setUserPosition(position_.coords);
                                    },
                                );
                                watchPositionIdRef.current = positionId;
                            },
                            () => {
                                alert('Cannot get current position. Please try with another browser or check permissions');
                            },
                        );
                    };
                    if (result.state === 'denied') {
                        if (watchPositionIdRef.current) {
                            navigator.geolocation.clearWatch(watchPositionIdRef.current);
                            watchPositionIdRef.current = undefined;
                        }
                        alert('Please allow your browser to locate your position');
                    } else if (result.state === 'prompt') {
                        setCurrentLocation();
                    } else if (result.state === 'granted') {
                        if (watchPositionIdRef && userPosition) {
                            localSetViewport(userPosition);
                        } else {
                            setCurrentLocation();
                        }
                    }
                });
        }
    };

    if (!viewport) {
        return null;
    }

    return (
        <Map
            ref={mapRef}
            viewport={viewport}
            vwAction={setViewport}
        >
            <Grid className="gridContainer" direction="row" container>
                <Grid md={3} sm={6} item>
                    <IconButton
                        className={locateIcon}
                        onClick={getLocation}
                    >
                        <MyLocationRoundedIcon
                            style={{ color: 'black' }}
                        />
                    </IconButton>
                    {width > 600 && (
                        <LeftPanel
                            circuits={circuits}
                            listCategory={listCategory}
                            listSubCategory={listSubCategory}
                            onCategoryChange={onCategoryChange}
                            onCircuitChange={setSelectedCircuit}
                            onClosePlaceDetail={() => {
                                setSelectedPlace(undefined);
                                setOpenPlacesDetail(false);
                            }}
                            onFiltersChange={setParametersFilters}
                            onPlaceSelect={(place) => {
                                if (place) {
                                    setSelectedPlace(place);
                                    setOpenPlacesDetail(true);
                                } else {
                                    // if cross icon is clicked
                                    setSelectedPlace(undefined);
                                    setOpenPlacesDetail(false);
                                }
                            }}
                            onSubCategoryChange={(subCat: string) => {
                                setSelectedSubCategory(selectedSubCategory === subCat ? undefined : subCat);
                            }}
                            onTabIndexChange={setTabIndex}
                            openCircuitDetails={openCircuitDetails}
                            openPlaceDetail={openPlacesDetail}
                            places={filteredPlaces}
                            selectedCircuit={selectedCircuit}
                            selectedPlace={selectedPlace}
                            selectedSubCategory={selectedSubCategory}
                            setOpenCircuitDetails={setOpenCircuitDetails}
                            tabIndex={tabIndex}
                        />
                    )}
                </Grid>
                <Grid justifyContent="flex-end" xs={2} item>
                    <RightPanel
                        onCitySelect={setCity}
                        resetLeftPanel={resetLeftPanel}
                    />
                </Grid>
            </Grid>

            {userPosition && (
                <Marker
                    latitude={userPosition.latitude}
                    longitude={userPosition.longitude}
                >
                    <img
                        alt='location icon'
                        loading='lazy'
                        src={`${baseUrl}/icons/apple-touch-icon.png`}
                        style={{ width: '45px', height: 'auto' }}
                    />
                </Marker>
            )}

            <Markers
                circuitDisplayed={selectedCircuit}
                mapRef={mapRef.current}
                onSiteClick={(place) => {
                    setSelectedPlace(place);
                    setMobileTabIndex(2);
                }}
                places={filteredPlaces}
                viewport={viewport}
                vwAction={setViewport}
            />
            <CircuitRoute
                circuit={selectedCircuit}
            />
            {width > 600 && (
                <PopupPlace
                    onClose={() => {
                        setSelectedPlace(undefined);
                        setOpenPlacesDetail(false);
                    }}
                    onDetailClick={() => {
                        setTabIndex(1);
                        setOpenPlacesDetail(true);
                    }}
                    place={selectedPlace}
                />
            )}
            {width <= 600 && (
                <NavMobile
                    circuits={circuits}
                    listCategory={listCategory}
                    listSubCategory={listSubCategory}
                    onCategoryChange={onCategoryChange}
                    onCircuitSelect={setSelectedCircuit}
                    onClosePlaceDetail={() => {
                        setSelectedPlace(undefined);
                        setOpenPlacesDetail(false);
                    }}
                    onFilterChange={setParametersFilters}
                    onPlaceSelect={(place: PlaceType) => {
                        if (place) {
                            setSelectedPlace(place);
                            setOpenPlacesDetail(true);
                        } else {
                            // if cross icon is clicked
                            setSelectedPlace(undefined);
                            setOpenPlacesDetail(false);
                        }
                    }}
                    onSubCategoryChange={(subCat: string) => {
                        setSelectedSubCategory(selectedSubCategory === subCat ? undefined : subCat);
                    }}
                    onTabIndexChange={setMobileTabIndex}
                    openCircuitDetails={openCircuitDetails}
                    places={filteredPlaces}
                    selectedCircuit={selectedCircuit}
                    selectedPlace={selectedPlace}
                    selectedSubCategory={selectedSubCategory}
                    setOpenCircuitDetails={setOpenCircuitDetails}
                    tabIndex={mobileTabIndex}
                />
            )}
        </Map>
    );
};

export default App;
