import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import React, { FC, useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Circuit } from '../../domain/models';
import { MapRoute } from '../../domain/models/mapRoute';
import MapboxService from '../../services/MapboxService';

const CircuitRoute: FC<{ circuit?: Circuit }> = ({ circuit }) => {
    const [itinerary, setItinerary] = useState<Feature<Geometry, GeoJsonProperties> | null>(null);

    // TODO: get from root.dataset
    const itineraryService = new MapboxService(
        'pk.eyJ1IjoibGdlb2YiLCJhIjoiY2wzYTVhNjg3MDJkOTNjbHB4ZTIxdXU0OCJ9.B5BxYMxF52CBvyXPcq76bg',
    );

    useEffect(() => {
        if (circuit) {
            const fetchData: () => Promise<MapRoute> = async () => {
                const data = await circuit.getItinerary(itineraryService);

                return data;
            };

            fetchData().then((data) => {
                const feature: Feature<Geometry, GeoJsonProperties> = {
                    geometry: data.geometry,
                    properties: {},
                    type: 'Feature',
                };
                setItinerary(feature);
            }).catch(console.error);
        }
    }, [circuit]);

    if (!circuit || !itinerary) {
        return null;
    }

    return (
        <Source data={itinerary} id="polylineLayer" type="geojson">
            <Layer
                id="lineLayer"
                layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                }}
                paint={{
                    'line-color': '#B026FF',
                    'line-width': 5,
                }}
                source="my-data"
                type="line"
            />
        </Source>
    );
};

export default CircuitRoute;
