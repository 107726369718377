import { ItineraryService } from '../../services/ItineraryService';
import { MapRoute } from './mapRoute';
import { PlaceType } from './place';

export type CircuitType = {
    id: string;
    name: {
        en: string;
        fr: string;
    };
    website: {
        en: string;
        fr: string;
    };
    shortDescription: {
        en: string;
        fr: string;
    };
    longDescription: {
        en: string;
        fr: string;
    };
    places: string[];
}

export class Circuit {
    id: string;

    name: {
        en: string;
        fr: string;
    };

    website: {
        en: string;
        fr: string;
    };

    shortDescription: {
        en: string;
        fr: string;
    };

    longDescription: {
        en: string;
        fr: string;
    };

    places: PlaceType[];

    itinerary?: MapRoute;

    constructor(circuit: Omit<CircuitType, 'places'> & { places: PlaceType[] }) {
        this.id = circuit.id;
        this.name = circuit.name;
        this.website = circuit.website;
        this.shortDescription = circuit.shortDescription;
        this.longDescription = circuit.longDescription;
        this.places = circuit.places;
    }

    async getItinerary(itineraryService: ItineraryService): Promise<MapRoute> {
        if (!this.itinerary) {
            this.itinerary = await itineraryService.getItinerary(this.places.map((place) => place.coordinates));
        }

        return this.itinerary;
    }
}
