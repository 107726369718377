import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import langEn from './translations/en.json';
import langFr from './translations/fr.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: langEn },
            fr: { translation: langFr },
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'em', 'span'],
        },
    });

export default i18n;
