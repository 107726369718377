import { Card, CardHeader, CardContent, Typography, Button, IconButton, Divider } from '@material-ui/core';
import { NearMe } from '@material-ui/icons';
import PreviousIcon from '@material-ui/icons/ArrowBackIos';
import { Rating } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaceType } from '../../domain/models';
import localizeName from '../../utils';
import { SiteIcon } from '../icons';
import './placeDetail.css';

type PlaceDetailProps = {
    place: PlaceType,
    onPrevious: () => void,
}

const DetailHeaderComponent: FC<PlaceDetailProps> = ({
    place,
    onPrevious,
}) => {
    const { t } = useTranslation();
    const url = `https://www.google.fr/maps/dir//
            ${place.coordinates.lat},+${place.coordinates.lon}/`;
    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <div className="headerContainer">
                <div className="previousIcon">
                    <IconButton aria-label="close" onClick={onPrevious} onKeyPress={onPrevious}>
                        <PreviousIcon />
                    </IconButton>
                </div>
                <div className="header">
                    <Typography color="textPrimary" component="p" variant="h5">
                        {localizeName(place.name)}
                    </Typography>
                </div>
            </div>
            <Typography className="address" color="textSecondary" component="p" variant="subtitle1">
                {place.address}
            </Typography>
            <div className="subheader">
                <Rating max={3} name="read-only" value={place.rating} readOnly />
                <div className="subSubHeader">
                    <Button
                        aria-label="go"
                        className="goThereButton"
                        href={url}
                        size="small"
                        tabIndex={0}
                        target="_blank"
                    >
                        <NearMe />
                        <Typography variant="subtitle1"> {t('placeDetail.go_there')} </Typography>
                    </Button>
                    {localizeName(place.website) && (
                        <Button
                            className='websiteButton'
                            color="primary"
                            href={localizeName(place.website)}
                            size="large"
                            target="_blank"
                        >
                            {t('placeDetail.website')}
                        </Button>
                    )}
                </div>
                <Typography color="textSecondary" component="p" variant="body2">
                    {place.shortDescription ? localizeName(place.shortDescription) : undefined}
                    {place.longDescription && (
                        isVisible ? (<p>{localizeName(place.longDescription)}</p>) : (
                            <div className="seeMoreButton">
                                <Button
                                    color="primary"
                                    onClick={() => setIsVisible(!isVisible)}
                                    size="small"
                                    type="button"
                                    variant="text"
                                >
                                    {`${t('placeDetail.see_more_button')}`}
                                </Button>
                            </div>
                        )
                    )}
                </Typography>
                {place.price && (
                    <div className="placePrices">
                        <Typography variant="subtitle1">
                            <strong>{`${t('placeDetail.price')}\n`}</strong>
                            {localizeName(place.price)}
                        </Typography>
                    </div>
                )}
            </div>
            <Typography variant="subtitle1">
                <strong>{t('placeDetail.hours')}</strong>
            </Typography>
            {
                Object.keys(place.schedule)
                    .map((day: string) => (
                        <Typography key={`${place.id}_${day}`} variant="subtitle1">
                            {`${t(`placeDetail.days.${day}`)} :
                            ${localizeName(place.schedule[day].openingTime)} - ${localizeName(place.schedule[day].closingTime)}`}
                        </Typography>
                    ))
            }

        </>
    );
};

const PlaceDetail: FC<PlaceDetailProps> = ({
    onPrevious,
    place,
}) => {
    const { t } = useTranslation();

    return (
        <div className="container">
            <Card>
                <div className="cardContainer">
                    <CardHeader
                        component={() => (
                            <DetailHeaderComponent
                                onPrevious={onPrevious}
                                place={place}
                            />
                        )}
                    />
                    <CardContent>
                        <div className="categories">
                            {place.categories.map((cat: string) => (
                                <div className="category">
                                    <SiteIcon
                                        category={cat}
                                        className="icons"
                                        hover={false}
                                    />
                                    <Typography color="textSecondary" component="p" variant="body2">
                                        {t(`category.${cat}`)}
                                    </Typography>
                                </div>
                            ))}
                        </div>

                        <div className="subCategories">
                            {place.subCategories.map((subCat) => (
                                <Typography
                                    className="subCategory"
                                    color="textSecondary"
                                    component="p"
                                    variant="body2"
                                > {t(`subCategory.${subCat}`)}
                                </Typography>
                            ))}
                        </div>
                    </CardContent>
                </div>
            </Card>
        </div>
    );
};

export default PlaceDetail;
