import { Typography, IconButton } from '@material-ui/core';
import AccessibleIcon from '@material-ui/icons/Accessible';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters } from '../../domain/models';
import './filtersParameters.css';
import { useParametersFilters } from '../context';

const FilterParameters: FC<{onFiltersChange: (filters: Filters) => void}> = ({ onFiltersChange }) => {
    const [rotateFilterArrow, setRotateFilterArrow] = useState(false);
    const [expandFilters, setExpandFilters] = useState(false);
    const { parametersFilters, setParametersFilters } = useParametersFilters();
    const { t } = useTranslation();
    const filters = parametersFilters;
    const setFilters = setParametersFilters;

    const handleClickOnFilter = () => {
        setRotateFilterArrow(!rotateFilterArrow);
        setExpandFilters(!expandFilters);
    };

    const getRatingColor = () => {
        switch (filters.rating) {
            case 1:
                return 'bronze';
            case 2:
                return 'silver';
            case 3:
                return 'gold';
            default:
                return '';
        }
    };

    return (
        <div className='filterContainer'>
            <div className='filterContent'>
                <IconButton
                    onClick={() => setFilters({ ...filters, free: !filters.free })}
                >
                    <div className='filterIcon'>
                        {filters.free
                            ? <MoneyOffRoundedIcon color='primary' fontSize='medium' />
                            : <AttachMoneyRoundedIcon fontSize='medium' />}
                        <span className='filterIconText'>{t('filters.free')}</span>
                    </div>
                </IconButton>
                <IconButton
                    onClick={() => setFilters({ ...filters, accessible: !filters.accessible })}
                >
                    <div className='filterIcon'>
                        <AccessibleIcon color={filters.accessible ? 'primary' : 'disabled'} fontSize='medium' />
                        <span className='filterIconText'>{t('filters.accessible')}</span>
                    </div>
                </IconButton>
                <IconButton
                    onClick={() => setFilters({ ...filters, kidsFriendly: !filters.kidsFriendly })}
                >
                    <div className='filterIcon'>
                        <ChildFriendlyIcon color={filters.kidsFriendly ? 'primary' : 'disabled'} fontSize='medium' />
                        <span className='filterIconText'>{t('filters.child_friendly')}</span>
                    </div>
                </IconButton>
                <IconButton
                    onClick={() => setFilters({
                        ...filters,
                        rating: filters.rating > 0 ? filters.rating - 1 : 3,

                    })}
                >
                    <div className='filterIcon'>
                        {
                            filters.rating
                                ? (
                                    <div className="ratingContainer">
                                        <StarIcon className={getRatingColor()} fontSize='medium' />
                                        <span className='ratingNumber'>{filters.rating}</span>
                                    </div>
                                )
                                : <StarOutlineRoundedIcon />
                        }
                        <span className='filterIconText'>{t('filters.rating')}</span>
                    </div>
                </IconButton>
            </div>
        </div>
    );
};

export default FilterParameters;
