import { Card, CardHeader, CardContent, Typography, IconButton } from '@material-ui/core';
import PreviousIcon from '@material-ui/icons/ArrowBackIos';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Circuit } from '../../domain/models';
import localizeName from '../../utils';
import './circuitDetails.css';

type CircuitDetailProps = {
    circuit: Circuit,
    onClose: () => void,
    onPrevious: () => void,
    onGoToSteps: () => void,
}

const DetailHeaderComponent: FC<{
    circuit: Circuit,
    onClose: () => void,
    onPrevious: () => void,
}> = ({
    circuit,
    onClose,
    onPrevious,
}) => {
    const { t } = useTranslation();

    return (
        <div className="headerComponent">
            <IconButton
                onClick={onPrevious}
                onKeyPress={onPrevious}
            >
                <PreviousIcon
                    className="icons"
                />
            </IconButton>
            <Typography color="textPrimary" component="p" variant="body1">
                {localizeName(circuit.name)} -
                { circuit.itinerary?.distance
                && ` ${Math.round(circuit.itinerary?.distance / 1000)} ${t('circuitDetails.distanceUnit')}`} -
                {circuit.itinerary?.duration
                && ` ${Math.round(circuit.itinerary?.duration / 60)} ${t('circuitDetails.durationUnit')}`}
            </Typography>
        </div>
    );
};

const CircuitDetail: FC<CircuitDetailProps> = ({
    circuit,
    onClose,
    onGoToSteps,
    onPrevious,
}) => {
    const { t } = useTranslation();

    return (
        <div className="circuitDetailContainer">
            <Card className='cardContainer'>
                <CardHeader
                    component={() => (
                        <DetailHeaderComponent
                            circuit={circuit}
                            onClose={onClose}
                            onPrevious={onPrevious}
                        />
                    )}
                />
                <CardContent className='contentComponent'>
                    <Typography
                        variant="body2"
                    >
                        {t('circuitDetails.numberOfSteps')} {circuit.places.length}
                    </Typography>
                    <div className="steps">
                        <Typography
                            color='textPrimary'
                            onClick={onGoToSteps}
                            variant='body2'
                        >{t('circuitDetails.stepsLink')}
                        </Typography>
                    </div>
                    <Typography
                        className="shortDescription"
                        component="p"
                        variant="body2"
                    >
                        {localizeName(circuit.shortDescription)}
                    </Typography>
                    <Typography className="longDescription" color="textSecondary" component="p" variant="body2">
                        {localizeName(circuit.longDescription)}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default CircuitDetail;
