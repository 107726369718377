import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Circuit, PlaceType } from '../../domain/models';
import localizeName from '../../utils';
import './searchBar.css';
import {
    useListCategory,
    useListSubCategory,
    useParametersFilters,
    useSelectedCategory,
    useSelectedSubCategory,
} from '../context';

const SearchBar: FC<{
    placesAndCircuits: any[],
    onSelection: (optionSelected: Circuit|PlaceType) => void,

}> = ({ placesAndCircuits, onSelection }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { setParametersFilters } = useParametersFilters();
    const { setSelectedCategory } = useSelectedCategory();
    const { setListCategory } = useListCategory();
    const { setSelectedSubCategory } = useSelectedSubCategory();
    const { setListSubCategory } = useListSubCategory();

    return (
        <Autocomplete
            className='searchBar'
            getOptionLabel={(option) => localizeName(option.name)}
            onChange={(_event, option) => {
                onSelection(option);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(_, value) => {
                if (value.length > 0 && !open) {
                    setParametersFilters({
                        free: false,
                        accessible: false,
                        kidsFriendly: false,
                        rating: 0,
                    });
                    setSelectedCategory(undefined);
                    setListCategory([]);
                    setSelectedSubCategory(undefined);
                    setListSubCategory([]);

                    setOpen(true);
                } else if (!value) { setOpen(false); }
            }}
            open={open}
            options={placesAndCircuits}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t('searchBar.placeholder')}
                />
            )}
            freeSolo
        />
    );
};

export default SearchBar;
