// @ts-nocheck
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';
import { Circuit, PlaceType } from '../../domain/models';
import localizeName from '../../utils';
import { SiteIcon } from '../icons';
import SitesCategories from '../sitesCategories';
import SubCategoriesList from '../subCategoriesList';
import './placesList.css';

type ExpandableListProps = {
    places: PlaceType[],
    onPlaceChange: (place: PlaceType) => void,
    onCategoryChange: (category: string) => void,
    onSubCategoryChange: (subcategory: string) => void,
    selectedCircuit?: Circuit,
    onCloseCircuit: () => void,
    selectedSubCategory?: string,
    listCategory?: Array<string>,
    listSubCategory?: Array<string>,
};

const PlacesList: FC<ExpandableListProps> = ({
    places,
    onPlaceChange,
    onCategoryChange,
    onSubCategoryChange,
    selectedCircuit,
    onCloseCircuit,
    // selectedCategory,
    selectedSubCategory,
    listCategory,
    listSubCategory,
}) => {
    if (!places) {
        return null;
    }

    return (
        <div>
            {/* Espaces Filtres */}
            {!selectedCircuit && (
                <div>
                    <SitesCategories
                        listCategory={listCategory}
                        onChange={onCategoryChange}
                    />
                    {listCategory?.length === 1 && (
                        <SubCategoriesList
                            handleListItemClick={onSubCategoryChange}
                            listSubCategory={listSubCategory}
                            selectedSubCategory={selectedSubCategory}
                            // subCategories={categoryMap[selectedCategory]}
                        />
                    )}
                </div>
            )}
            {selectedCircuit && (
                <div className="selectedCircuit">
                    <Typography
                        color="textPrimary"
                        variant="subtitle1"
                    >{localizeName(selectedCircuit.name)}
                    </Typography>
                    <IconButton
                        onClick={() => onCloseCircuit()}
                        onKeyPress={() => onCloseCircuit()}
                    >
                        <Close />
                    </IconButton>
                </div>
            )}
            <List component="nav">
                {places.map((place: PlaceType, index: number) => (
                    <ListItem
                        key={`place-${place.id}`}
                        onClick={() => onPlaceChange(place)}
                        onKeyPress={() => onPlaceChange(place)}
                        button
                    >
                        {/* TODO: localize */}
                        {selectedCircuit && (
                            <ListItemText
                                className='placeIndexText'
                                primary={`${index + 1}.`}
                            />
                        )}
                        <ListItem
                            className="placeIconsSide"
                        >
                            <SiteIcon
                                category={place.mainCategory}
                                className="placeIcons"
                                // onClick={() => setPlacesFilter({ ...placesFilter })}
                            />
                        </ListItem>
                        <ListItemText
                            className="placePrimaryText"
                            primary={localizeName(place.name)}

                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default PlacesList;
