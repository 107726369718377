import React, { useState, FC } from 'react';
import './rightPanel.css';
import { useTranslation } from 'react-i18next';
import { CityType } from '../../domain/models';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import localizeName from '../../utils';
import { useBaseUrl, useCity, useCities } from '../context';

interface AdditionnalProps {
    onCitySelect: (city: CityType) => void
    resetLeftPanel: () => void
}

const RightPanel: FC<AdditionnalProps> = ({ onCitySelect, resetLeftPanel }) => {
    const baseUrl = useBaseUrl();
    const { city: selectedCity, setCity: setSelectedCity } = useCity();
    const cities = useCities();
    const { width } = useWindowDimensions();
    const smallDisplay = width <= 600;
    const [displayIcons, setDisplayIcons] = useState(false);
    const { t } = useTranslation();

    const onSelect = (citySelected: CityType) => {
        setSelectedCity(citySelected);
        onCitySelect(citySelected);
        resetLeftPanel();
    };

    return (
        <div className='rightPanel'>
            {smallDisplay && (
                <div
                    className='cityExpand'
                    onClick={() => setDisplayIcons(!displayIcons)}
                    onKeyDown={() => setDisplayIcons(!displayIcons)}
                    role='button'
                    tabIndex={0}
                >
                    <img
                        alt={t('rightPanel.open_city_panel')}
                        className="rightIcons"
                        loading='lazy'
                        src={`${baseUrl}/icons/cities.svg`}
                    />
                    <div className='cityName'>{t('rightPanel.cities')}</div>
                </div>
            )}
            {(!smallDisplay || displayIcons) && (
                <div>
                    {cities.map((city: CityType) => selectedCity.id !== city.id
                            && (
                                <div
                                    key={city.id}
                                    className='backGroundIcons'
                                    id={city.id}
                                    onClick={() => onSelect(city)}
                                    onKeyDown={() => onSelect(city)}
                                    role='button'
                                    tabIndex={0}
                                >
                                    <img
                                        alt={t('rightPanel.select_city', { cityName: localizeName(city.name) })}
                                        className="rightIcons"
                                        loading='lazy'
                                        src={`${baseUrl}/icons/${city.icon}`}
                                    />
                                    <div className='cityName'>{localizeName(city.name)}</div>
                                </div>
                            ))}
                </div>
            )}
        </div>
    );
};

export default RightPanel;
