import { CoordinatesType } from '../domain/models';
import { MapRoute } from '../domain/models/mapRoute';
import { ItineraryService } from './ItineraryService';

class MapboxService implements ItineraryService {
    token: string;

    baseUrl: string;

    constructor(token: string) {
        this.token = token;
        this.baseUrl = 'https://api.mapbox.com';
    }

    async getItinerary(coordinates: CoordinatesType[]): Promise<MapRoute> {
        // eslint-disable-next-line max-len
        const url = `${this.baseUrl}/directions/v5/mapbox/walking/${this.formatCoordinates(coordinates)}?continue_straight=true&exclude=ferry&geometries=geojson&language=en&overview=simplified&steps=true&access_token=${this.token}`;

        const response = await (await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })).json();

        const itinerary: MapRoute = response.routes[0];

        return itinerary;
    }

    private formatCoordinates(coordinates: CoordinatesType[]): string {
        return coordinates.map((coord) => `${coord.lon},${coord.lat}`).join('%3B');
    }
}

export default MapboxService;
